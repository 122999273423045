import React, { useContext } from 'react'
import { useState } from 'react';
import styled from 'styled-components';
import Loading from '../../../components/common/Loading';
import Layout from '../../../components/layout';
import Input from '../../../components/layout/Input';
import { AppContext } from '../../../contexts/AppContext';
import axios from 'axios';
import { navigate } from 'gatsby-link';
import { SET_USER } from '../../../reducers/AppReducer';
import InputButton from '../../../components/common/InputButton';
import SEO from '../../../components/seo';
import { Link } from 'gatsby';
import DefaultButton from '../../../components/common/Button';

const Container = styled.div`
    position: relative;
    top: 20vh;
`

const Form = styled.form`
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    max-width: 600px;
    height: 400px;
    justify-content: space-between;
    align-items: center;
`

const InputTitle = styled.div`
    font-size: 12pt;
    color: ${props => props.theme.default.colors.mediumGrey};
    margin-bottom: 7px;
`

const InputGroup = styled.div`
`

const Inputs = styled.div`
    margin-bottom: 20px;
`

const Title = styled.h2`
    color: ${props => props.theme.default.colors.primary};
    margin-bottom: 50px;
`

const SuccessMessage = styled.span`
    color: ${props => props.theme.default.colors.primary};
    font-weight: 600;
`

const Error = styled.div`
    font-weight: 500;
    margin-left: 10px;
    margin-top: 5px;
    display: block;
    color: rgb(255, 85, 85);
    font-size: 8pt;
`

const _Loading = styled(Loading)`
    min-height: 50px !important;
`



export default function Login() {

    const { dispatch } = useContext(AppContext);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [sent, setSent] = useState(false)

    const submit = async e => {

        e.preventDefault();
        const apiUrl = process.env.GATSBY_API_ENDPOINT;
        // const apiUrl = 'http://localhost:5000';
        // const apiUrl = "https://api-xeu74yuiwq-ue.a.run.app"

        setLoading(true)
        setError("")
        try {
            await axios.post(`${apiUrl}/auth/reset`, {
                email: username, 
            })
            setSent(true)
        } catch (e) {
            console.error("Login failed")
            setError("Algo falhou ao entrar, se continuar a acontecer contacte-nos em geral@evazenergy.pt")
        }
        setLoading(false)
    }

    return (
        <Layout>
            {/* <SEO title={'Área de Cliente'.toUpperCase()} /> */}

            <Container>
                <Form>
                    <Title>{'Recuperar Password'.toUpperCase()}</Title>
                    <small>Insira o seu email para receber um link de recuperação</small>
                    <Inputs>
                        <InputGroup>
                            <InputTitle>Email</InputTitle>
                            <Input type={"email"} title={"email"} value={username} onChange={
                                e => setUsername(e.target.value)
                            }></Input>
                        </InputGroup>
                    </Inputs>
                    {loading ? <_Loading /> : sent ? null : <InputButton type={"submit"} onClick={e => submit(e)} value="Enviar"></InputButton> }
                    {error ? <Error>{error}</Error> : null}
                    {sent ? <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                            <SuccessMessage>Email de recuperação enviado, verifique o seu email</SuccessMessage>
                            
                        </div> : null}
                    <DefaultButton primary={0} type={"submit"} onClick={() => navigate("/account")} label="Voltar"></DefaultButton>
                </Form>
            </Container>
        </Layout>
    )
}
